import $ from 'jquery';

export const loadingOn = () => {
    $('#loader').show();
    $('#content-custom').css('filter', 'blur(2px)');
}

export const loadingOff = () => {
    $('#loader').hide();
    $('#content-custom').css('filter', 'none');
}

/**
 * Generic function for show a message into a div
 *
 * @param type (success|info|warning|danger)
 * @param title
 * @param message
 * @param divId
 */
export const showMessage = (type, title, message, divId) => {
    let msg = "";
    msg += '<div class="alert alert-' + type + '">';
    msg += '<a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>';
    msg += '<strong>' + title + '</strong> ' + message;
    msg += '</div>';
    msg += '<br>';
    if (typeof divId === "undefined") {
        $('.msg-box').html(msg);
    } else {
        $('#' + divId).html(msg);
    }
}

/**
 * Return an age from a date
 *
 * @param year
 * @param month
 * @param day
 * @returns {number}
 */
export const getAge = (year, month, day) => {
    let today = new Date();
    let birthDate = new Date(year, month, day);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const getCurrentMonthAllowed = () => {
    let curr = new Date().getUTCMonth();
    curr = (curr === 4) ? curr-- : curr;
    return curr
}

export const closeModalConfirmCancel = () => {
    $('#confirm_logout').modal('hide');
    loadingOn();
}

export const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
}

export const enableDaysInCurrentMonth = () => {
    let dayOp = document.getElementById("days").getElementsByTagName("option");
    let day = document.getElementById("days").value;
    let month = document.getElementById("months").value;
    let year = document.getElementById("years").value;
    let days = getDaysInMonth(month, year);
    for (let i = 0; i < 31; i++) {
        if (i < days) {
            dayOp[i].disabled = false
        } else {
            dayOp[i].disabled = true;
            if (day > i) {
                dayOp[i-1].selected = true;
            }
        }
    }
}

export const getFormattedDayOrMonth = (i, loop = false) => {
    let f = "";
    if (i < 9) {
        f = f + "0";
        let d = loop ? i + 1 : i;
        f = f + d.toString();
    } else {
        let d = loop ? i + 1 : i;
        f = f + d.toString();
    }
    return f;
}