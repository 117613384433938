import { Navigate, Route, Routes } from "react-router-dom";
import { Layout } from "./components";
import { AgeGate, PhoneForm, SignUp, Sorry, Success, Welcome } from "./routes";

const App = () => {
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Welcome />} />
                <Route path=":id" element={<Welcome />} />
                <Route path="step0" element={<AgeGate />} />
                <Route path="sorry" element={<Sorry />} />
                <Route path="step1" element={<PhoneForm />} />
                <Route path="step3" element={<SignUp />} />
                <Route path="success" element={<Success />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Route>
        </Routes>
    );
};

export default App;
