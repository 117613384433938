import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../../contexts/ContextProvider";

import routes from "../routes";
import { loadingOff } from "../../utils/common";

import SparrkupLogo from "../../assets/images/sparkup.png";
import SuccessIcon from "../../assets/images/icon-success.png";
import HealthBanner from "../../assets/images/health-banner.jpg";

export const Success = () => {
    const isSocialLinkActive = process.env.REACT_APP_ACTIVE_SOCIAL_LINK;
    const contentURL = process.env.REACT_APP_URL;
    const { t } = useTranslation();
    const { userPhone } = useStateContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (userPhone === null) return navigate(routes.home);

        loadingOff();
    }, []);

    return (
        <section className="sorry-section" style={{ marginTop: "-30px" }}>
            <img
                className="img-fluid health-banner"
                src={HealthBanner}
                alt="health banner"
            />
            <div className="text-center">
                <div className="logo">
                    <Link to={routes.home}>
                        <img src={SparrkupLogo} alt="sparrkup logo" />
                    </Link>
                </div>
            </div>
            <div className="text-center">
                <div className="mt-30">
                    <img src={SuccessIcon} alt="success icon" />
                </div>
            </div>
            <h3 className="mt-30">{t("page.success.title")}</h3>
            {/*<h4 className="title-center">{t('page.success.description')}</h4>*/}
            {isSocialLinkActive && (
                <div className="btn-telegram">
                    <a
                        href={`${contentURL}/content/landing.php?cellphone=237-6-${userPhone.slice(1)}`}
                        className="btn-green"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t("page.success.button")}
                    </a>
                </div>
            )}
        </section>
    );
};
