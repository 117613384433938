import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// translations
import en from "../lang/en.json";
import fr from "../lang/fr.json";

const environment = process.env.NODE_ENV;

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: environment === 'development',
        fallbackLng: "en",
        lng: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : "fr",
        resources: {
            en: {
                translation: {
                    page: en,
                }
            },
            fr: {
                translation: {
                    page: fr,
                },
            },
        },
        react: {
            transSupportBasicHtmlNodes: true,
          }
    });
