import { Fragment } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ReactComponent as Loader } from "../../assets/images/loader.svg";
import FooterImg from "../../assets/images/footer-img.png";
import enIcon from "../../assets/images/en.png";
import frIcon from "../../assets/images/fr.png";

const languages = ["en", "fr"];

export const Layout = () => {
    const { pathname } = useLocation();
    const { i18n } = useTranslation();

    const footerURLS = ["/", "/sorry", "/success"]; // URLs path that contain the footer image
    const languageURLS = ["/step3", "/success"]; // URLs path that must not contain the language option

    return (
        <Fragment>
            <div className="container">
                <div className="row position-relative">
                    {/*<!-- loader -->*/}
                    <div className="loader" id="loader">
                        <Loader />
                    </div>
                    {!languageURLS.includes(pathname) && (
                        <div
                            className="col-12 d-flex justify-content-end position-absolute"
                            style={{ top: "-60px", right: "25px" }}
                        >
                            {languages.map((lng) => (
                                <img
                                    key={lng}
                                    src={lng === "en" ? enIcon : frIcon}
                                    alt={`${lng} icon`}
                                    className={`translation-icon ${
                                        i18n.resolvedLanguage === lng
                                            ? "translation-icon__disabled"
                                            : ""
                                    }`}
                                    onClick={() => i18n.changeLanguage(lng)}
                                />
                            ))}
                        </div>
                    )}
                    <div className="col" id="content-custom">
                        {/*<!--  CONTENT -->*/}
                        <Outlet />
                        {/*<!--  /CONTENT -->*/}
                    </div>
                </div>
            </div>
            {(footerURLS.includes(pathname) || /RC/.test(pathname)) && (
                <div className="bottom-img">
                    <img src={FooterImg} alt="footer" />
                </div>
            )}
        </Fragment>
    );
};
